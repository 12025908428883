/* For WebKit-based browsers (Chrome, Safari, etc.) */
::-webkit-scrollbar {
    width: 7px !important;
    height: 7px !important;
}

::-webkit-scrollbar-thumb {
    background: #000c7c !important;
}

