.card {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  margin: 16px;
  padding: 16px;
}

.allobs {
  margin-bottom: 16px;
}

.obs-area {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
  padding: 8px;
  width: 100%;
  resize:none;
}

.dropzone {
  position: relative;
}

/* .select-label {
  display: block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}

.select-input {
  appearance: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  height: 36px;
  padding: 8px;
  position: relative;
  width: 50%;
  cursor: pointer;
  margin: 10px;


}

.select-input:focus {
  border-color: #0077ff;
  outline: none;
}

.select-input option {
  background-color: #fff;
  color: #333;
}

.select-input option:hover {
  background-color: #f5f5f5;
}

.select-input option:checked,
.select-input option:hover {
  background-color: #0077ff;
  color: #fff;
} */


.dropzone input[type="file"] {
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.dropzone-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin: 10px;
}


.modal-overlay-step6 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-btn {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #ddd;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

iframe{
  width: 80vw;
  height: 70vh;
  z-index: 9999999999;
  display: flex;
  flex-direction: column;
  margin: auto;


}

