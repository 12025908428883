/* Style for the container of the upload area and the uploaded files */
.Upload {
  display: flex;
  justify-content: center; 
  align-items: flex-start; 

}

/* Style for the drop field */
.dropfield {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 300px;
  border: 2px dashed black;
  cursor: pointer;
  text-align: center;
}
.dropfield svg {
  opacity: 0.3;
  width: 100%;
  

}

.droplabel {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
}

/* Style for the drop alert */
.dropalert {
  margin-top: 16px;
  text-align: center;
  font-size: 14px;

}

/* Style for the list of uploaded files */
.showUploadedFiles {
  margin-top: 16px;
  text-align: center;
  margin-left: 16px; /* Add this line to create some space between the drop field and the list */

}

 p {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;

}

.showUploadedFiles ul {
  padding: 0;
  list-style-type: none;

}

.showUploadedFiles li {
  display: flex;
  justify-content:left;
  align-items: center;
  margin-bottom: 8px;
  padding: 8px;
  border: 1px solid black;


}


.showUploadedFiles li svg {
  width: 24px;
  height: 24px;
  fill: currentColor;
  cursor: pointer;

}


.column{
  flex-basis: 50%;
  margin: 10px;

}


@media only screen and (max-width: 600px) {
  .Upload{
      display: block;
  
    }  
  .column { 
      flex-basis: 100%;
  }
}
