

input.inputNumber::-webkit-inner-spin-button,
input.inputNumber::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    display: none;
    -moz-appearance: textfield;
    appearance: textfield;

}
