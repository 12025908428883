.modal-overlay-step3 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  max-width: 90%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.modal-left img {
  max-height: 400px;
  max-width: 100%;
  margin-bottom: 20px;
}


@media (min-width: 768px) {
  /* Show image and text side-by-side on larger screens */
  .modal-content {
    flex-direction: row;
  }
  
  .modal-left img {
    margin-bottom: 0;
    margin-right: 20px;
  }
  
  .modal-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .modal-content button {
    align-self: flex-end;
    margin-top: auto;
  }
}

.modal-content h2 {
  margin-top: 0;
}

.modal-content button {
  margin-top: 20px;
  padding: 5px 10px;
  background-color: #ddd;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}


  
  .close-btn {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #ddd;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .imgTheme{
    max-width: 30%;
    max-height: 100%;
    
    margin-right: 10px;
    margin-bottom: 10px;
    
    border-radius: 10px;
    cursor: pointer;
    
  }
  .imgTheme:hover{
    filter: blur(5px);

  }

  .imgTheme:hover::before {
    content: "\f00e"; /* Magnifying glass icon Unicode character */
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 36px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    opacity: 0.8;
  }
  
.nome{
  font-size:15pt;

  font-weight: bold;

  /* padding: 10px; */


}

.chooseTheme{
font-size:12pt;
display:flex;

font-weight: bold;
color: black;

margin-top: 10px;
margin-bottom: 10px;

/* perspective: 500px; */
transition: transform 0.5s;
transform-origin: top left;

cursor: pointer;


}
.chooseTheme:hover{
/* font-size:15pt; */
color: #1da1f2;


transform: scale(1.2);


}

.modal-content-confirm{
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  max-width: 100%;
  max-height: 100%;
  min-width: 100px;
  min-height: 100px;
  /* display: flex; */
  /* flex-direction: column; */

}
.all-content{
  width: 100%;
  display: flex;
}
.alert-img{
width: 30%;
height: 100%;
}
.alert-img>svg{
width: 100%;
height: 100%;
}
.alert-txt{
width: 100%;
}
.alert-txt>h1{
font-size: 25pt;
font-weight: bold;
}
.alert-txt>p{
font-size: 15pt;
}
.themeName{
  color: #1da1f2;
}



@media (max-width: 768px) {
  .modal-content-confirm{
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 90vw;
    max-height: 90%;
    height: auto;
    display: flex;
    flex-direction: column;  
  
  }


  .all-content{
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .alert-img{
    width: 30%;
    height: 100%;
    margin: auto;
    }
    
  
}
