* {
    box-sizing: border-box;
  }
  
  .card {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #F9FAFB;
    border-radius: 8px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  fieldset {
    border: none;
    padding: 0;
    margin: 0;
  }
  
  legend {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #6B7280;
  }
  
  textarea {
    width: 100%;
    padding: 12px;
    margin-bottom: 24px;
    border-radius: 8px;
    border: 1px solid #D1D5DB;
    resize: vertical;
    font-size: 16px;
    color: #374151;
    resize: none;
  }
  
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
    color: #374151;
  }
  
  .personal-data label,
  .address label {
    display: inline-block;
    width: 100%;
    margin-bottom: 4px;
  }
  
 
  
  h4 {
    font-weight: bold;
    margin-top: 32px;
    margin-bottom: 16px;
    font-size: 20px;
    color: #374151;
  }
  
  .do-columns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .personal-data,
  .address {
    flex-basis: 48%;
  }
  
  .personal-data {
    order: 1;
  }
  
  .address {
    order: 2;
  }
  
  .address .form-columns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .address .form-columns div {
    flex-basis: 48%;
  }
  
  @media (max-width: 768px) {
    .personal-data,
    .address {
      flex-basis: 100%;
    }
    .address .form-columns div {
      flex-basis: 100%;
    }
  }
  

  .formData{
    display: flex;

  }

  .column{
    flex-basis: 50%;
    margin: 10px;

  }
  

  @media only screen and (max-width: 600px) {
    .formData{
        display: block;
    
      }  
    .column { 
        flex-basis: 100%;
    }
  }

  
  .dataWrong{
    border: 1px solid #ff0000;

  }

.dateDIV:not(.datePick){
  /* display: flex; */
  /* align-items: center; */
  /* vertical-align: middle; */


  /* background-color: #ff0000; */


}

.datePick{
  width: 100%;
  /* background-color: aqua; */

  /* display: flex; */
  /* align-items: center; */
  /* vertical-align: middle; */
  /* justify-content: center; */


}

.TextField{
  width: 100%;
}

.borderColorRed{
  border-color: #ff0000;
}

.boxButton{
  display: grid;
  grid-template-columns: 1fr auto;

  margin-top: 40px;
  margin-bottom: 40px;


}
.saveButton{
  /* grid-column-start: 2; */
}