.card {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #F9FAFB;
    border-radius: 8px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

label{
    font-size: 8pt; 
    font-weight: bold;
    margin: 10px;
  }

.selectExpiration{
    width: 200px;
    max-width: 200px; 

    margin-bottom: 24px;
    border-radius: 8px;
    border: 1px solid #D1D5DB;
    font-size: 16px;
    color: #374151;
    background-color: #F3F4F6;
    transition: border-color 0.2s ease-in-out;

}


  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: grey;
    transition: 0.4s;
    border-radius: 24px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  


  