.result-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.waiting-container {
  background-color: #EFEFEF;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
}

.approved-container {
  background-color: #FFF;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
}

.approved-card {
  background-color: #09ff0093;
  border-radius: 0.5px;
  padding: 10px;
  margin: 20px auto;
  width: 50%;
  max-width: 800px;
}
.not-approved-card {
  background-color: #ff00005b;
  border-radius: 0.5px;
  padding: 10px;
  margin: 20px auto;
  width: 50%;
  max-width: 800px;
}

.steps-container {
  margin: 20px auto;
  max-width: 800px;
  text-align: left;
}

/* .steps-list {
  list-style: none;
  padding: 0;
}

.step-item {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.step-number {
  background-color: #767B91;
  color: #FFF;
  border-radius: 50%;
  width: 100px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin-right: 10px;
}

.step-description {
  margin-left: 10px;
} */


.steps-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.step-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.step-number {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #0082d9c2;
  border-radius: 20%;
  margin-right: 10px;
  position: relative;
}

.step-number::before {
  content: "";
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 15px solid #0082d9c2;
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 10px;
}

.step-description {
  font-size: 14px;
  line-height: 1.5;
}
