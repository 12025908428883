.modal-overlay-step4 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
  }
  
  .close-btn {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #ddd;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  

    
  .adjust-content{
    width: 100%;
  } 
  .adjust-content>h1{
    font-size: 30pt;
    font-weight: bold;
  } 
  .adjust-content>p{
    font-size: 20pt;
  } 

  .titles{
    font-size:20px;
    font-weight:bold;
  }

  .timeleftcard{
      font-size:20px;
      text-align:right;
      max-width:50%;
      border-radius:10px;
      padding:5px;
      font-weight:bold;
  }